<template>
  <list-container :list="products" @load="loadMoreProducts" @query="queryProducts">
    <template v-slot:items>
      <div v-if="drawItems">
        <div v-for="(product, index) in products.products" :key="product.product_id" @click="update(product.product_id)">
          <list-image-item-container :printBorder="index < count">
            <template v-slot:image>
              <div class="product-image-container">
                <v-img class="product-image" :src="getCoverImage(index)" aspect-ratio="1"></v-img>
              </div>
            </template>
            <template v-slot:content>
              <div class="list-item-title first-capital-letter">
                {{ product.name }}
              </div>
              <div v-if="$vuetify.breakpoint.smAndUp">
                <div class="category-content" v-for="(path, index) in product.category.path_from_root" :key="path.category_id">
                  {{path.name}}
                  <span v-if="index < product.category.path_from_root.length-1" class="category-separator">
                    >
                  </span>
                </div>
              </div>
              <div v-else>
                <span class="category-content">{{product.category.name}}</span>
              </div>
              <div class="product-price">
                {{ getHumanizedPrice(index) }}
              </div>
              <div class="product-quantity">
                {{ getHumanizedQuantity(index) }}
              </div>
              <div class="product-brand first-capital-letter">
                {{ product.brand }}
              </div>
            </template>
          </list-image-item-container>
        </div>
      </div>
    </template>
  </list-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import ListContainer from '@/components/List/ListContainer'
import ListImageItemContainer from '@/components/List/ListImageItemContainer'
import { currency } from '@/shared/format'

export default {
  mixins: [navigationMixin],
  components: {
    ListContainer,
    ListImageItemContainer
  },
  computed: {
    ...mapState('product', ['products']),
    ...mapGetters('product', ['getCoverImage', 'getHumanizedPrice', 'getHumanizedQuantity']),
    drawItems () {
      return this.products && this.products.products
    },
    count () {
      return this.products.products.length - 1
    }
  },
  methods: {
    currency,
    ...mapActions('product', ['getProducts', 'getMoreProducts']),
    update (id) {
      this.goToRouteByName('update-product', { id })
    },
    loadMoreProducts (url) {
      this.getMoreProducts(url)
    },
    queryProducts (search) {
      this.getProducts(search)
    }
  },
  mounted () {
    this.getProducts()
  }
}
</script>
<style scoped>
.product-image {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 0.5rem;
}
.product-image-container {
  position: relative;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 0.5rem;
}
.category-content {
  color: #9E9E9E;
  font-size: 0.813rem;
  display: inline-block;
}
.category-separator {
  margin-left: 0.3rem;
  margin-right: 0.5rem;
}
.list-item-description {
  font-size: 0.875rem;
}
.product-price {
  text-align: left;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 2rem;
}
.product-quantity {
  text-align: left;
  font-size: 0.875rem;
}
.product-brand {
  text-align: left;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #7d7d7d;
}
/* 600px */
@media only screen and (max-width: 37.5rem) {
  .product-image {
    width: 6.875rem;
    height: 6.875rem;
    border-radius: 0.375rem;
  }
  .product-image-container {
    width: 6.875rem;
    height: 6.875rem;
    border-radius: 0.375rem;
  }
  .list-item-title {
    padding-top: 0.125rem;
    font-size: 0.938rem;
    line-height: 1.125rem;
  }
}
</style>
